import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent implements OnInit {
  @Input() caption?: string;
  @Input() href?: string;
  @Input() target?: string;
  @Input() iconClass?: string;
  @Input() download?: string;
  @Input() action?: () => void;
  @Input() color?: string;

  private readonly smallWidth = 600;
  private readonly smallHeight = 515;

  private privateHovering = false;
  private get hovering(): boolean { return this.privateHovering; }
  private set hovering(value: boolean) {
    this.privateHovering = value;
    this.showIcon = (!this.hovering || this.smallScreen);
    this.showCaption = (this.hovering || this.smallScreen);

    if (value) {
      this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'border-color', this.color);
      this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'background-color', this.color);
      this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'color', 'white');
    } else {
      this.renderer.removeStyle(this.el.nativeElement.childNodes[0], 'border-color');
      this.renderer.removeStyle(this.el.nativeElement.childNodes[0], 'background-color');
      this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'color', this.color);
    }
  }

  private privateSmallScreen = false;
  private get smallScreen(): boolean { return this.privateSmallScreen; }
  private set smallScreen(value: boolean) {
    this.privateSmallScreen = value;
    this.showIcon = (!this.hovering || this.smallScreen);
    this.showCaption = (this.hovering || this.smallScreen);
  }

  showIcon = false;
  showCaption = false;

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    this.smallScreen = (window.innerHeight <= this.smallHeight ||
      window.innerWidth <= this.smallWidth);
    this.showIcon = (!this.hovering || this.smallScreen);
    this.showCaption = (this.hovering || this.smallScreen);
  }

  onClick(): void {
    if (this.action !== null && this.action !== undefined) {
      this.action();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const isSmall = (event.target.innerWidth <= this.smallWidth ||
      event.target.innerHeight <= this.smallHeight);
    if (isSmall !== this.smallScreen) {
      this.smallScreen = isSmall;
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.hovering = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hovering = false;
  }
}
