<main>
    <app-nav-button class="resume"
        download="krisana-crockett.pdf"
        target="_blank"
        href="../assets/resume.pdf"
        iconClass="bit bi-file-text-fill"
        color="#68D9DB"
        caption="Resume"></app-nav-button>
    <app-nav-button class="linkedin"
        href="https://www.linkedin.com/in/krisana-crockett"
        target="krisana-linkedin"
        iconClass="bi bi-linkedin"
        color="#FEA7F5"
        caption="LinkedIn"></app-nav-button>
    <app-nav-button class="contact"
        href="mailto:Krisana.Crockett@Gmail.com"
        iconClass="bi bi-envelope-fill"
        color="#6F5CCB"
        caption="Email"></app-nav-button>
</main>
